import {getCurrencies} from "../api/exchange";

export const loadConfig = async (global, setGlobal) => {

    let newGlobal = {...global}

    newGlobal = {
        ...newGlobal,
        isLoading: true,
    }
    /*setGlobal(newGlobal)*/

    try {

        const getCurrenciesFunc = await getCurrencies()

        newGlobal = {
            ...newGlobal,
            isLoading: false,
            accessDenied: false,
            hasError: false,
            currencies: getCurrenciesFunc?.data?.data?.currencies,
            refs: getCurrenciesFunc?.data?.data?.refs,
            prices: getCurrenciesFunc?.data?.data?.prices,
            countries: getCurrenciesFunc?.data?.data?.countries,
            defaultCountry: getCurrenciesFunc?.data?.data?.defaultCountry,

            language: getCurrenciesFunc?.data?.data?.language,
            direction: getCurrenciesFunc?.data?.data?.direction,
            datePickerLocale: getCurrenciesFunc?.data?.data?.datePickerLocale,
            calendar: getCurrenciesFunc?.data?.data?.calendar,
            voucherSupport: getCurrenciesFunc?.data?.data?.voucherSupport,
        }

    } catch (e) {

        if (e?.response?.status === 503) {
            newGlobal = {
                ...newGlobal,
                isLoading: false,
                accessDenied: true,
            }
        } else {
            newGlobal = {
                ...newGlobal,
                isLoading: false,
                hasError: true,
            }
        }


    }

    setGlobal(newGlobal)

}