import React, {useState} from 'react';
import classes from './IPG.module.css';
import {Trans, useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import NumberInput from "../../../../../../components/NumberInput/NumberInput";
import {BN, getCurrencyNameOrAlias, parsePriceString} from "../../../../../../utils/utils";
import Button from "../../../../../../components/Button/Button";
import Loading from "../../../../../../components/Loading/Loading";
import {sendWithdrawReq} from "../../../../../../api/exchange";
import toast from "react-hot-toast";
import i18next from "i18next";
import {sendIpgReq} from "../../../../../../api/ipg";
import useAuth from "../../../../../../Hooks/useAuth";
import {useLocation} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";
import {useGetKycStatus} from "../../../../../../query";

const IPG = () => {

    const {t} = useTranslation();
    const {global, setGlobal} = useGlobal();
    const {auth} = useAuth();

    const {data: ksData} = useGetKycStatus()

    const apiBaseUrl = window.env.REACT_APP_API_BASE_URL;

    const useIpg = window.env.REACT_APP_USE_IPG?.toLowerCase() === 'true';

    const location = useLocation();

    const callBackUrl = `${window?.location?.origin}${RoutesName.PaymentResult}`

    const [amount, setAmount] = useState({value: new BN(0), error: []});

    const [loading, setLoading] = useState(false)

    const language = global?.language
    const gateway = global?.selected_deposit_network
    const precision = global?.currencies[gateway?.currencySymbol]?.precision


    const minDeposit = gateway?.depositMin
    const maxDeposit = gateway?.depositMax

    const validation = (value) => {

        const newAmount = {...amount}
        newAmount.error = []



       /* if (new BN(value).isGreaterThan(new BN(freeWallet))) {
            newAmount.error = t('Withdrawal.noInventory')
        }

        if (new BN(value).isEqualTo(new BN(freeWallet).decimalPlaces(precision))) {
            newAmount.error = t('Withdrawal.noInventorywithdrawFee')
        }*/



        if (new BN(value).isLessThan(new BN(minDeposit))) {
            newAmount.error = t('Deposit.allowableMinDeposit')
        }
        if (new BN(value).isGreaterThan(new BN(maxDeposit))) {
            newAmount.error = t('Deposit.allowableMaxDeposit')
        }

     /*   if (new BN(value).minus(new BN(withdrawFee)).isLessThanOrEqualTo(0)) {
            newAmount.error = t('Withdrawal.noInventorywithdrawFee')
        }


        if (new BN(value).isLessThan(new BN(withdrawFee)) ) {
            newAmount.error = t('Withdrawal.allowableWithdraw')
        }*/


        newAmount.value = new BN(value)
        setAmount(newAmount)
    }



    const submit = async () => {
        setLoading(true);

        const ipgRequestData = {
            "amount": amount?.value,
            "currency": gateway?.currencySymbol,
            "callbackUrl": callBackUrl,
            "description": `Deposit - ${amount?.value} - ${gateway?.currencySymbol} - ${auth?.mobile}`,
            "mobile": auth?.mobile,
            "cardNumber": null,
            "nationalCode": null
        };

        const newWindow = window.open("", "_blank");
        try {
            const res = await sendIpgReq(ipgRequestData, auth?.token);
            newWindow.location.href = `${apiBaseUrl}/ipg/pay/${res?.data?.data?.reference}`;
            setAmount({ value: new BN(0), error: [] });
        } catch (err) {
            if (newWindow) {
                newWindow.close();
            }
            if (i18next.exists('ErrorMessages.' + err?.response?.data?.message)) {
                toast.error(t("ErrorMessages." + err?.response?.data?.message));
            } else {
                toast.error(t("serverError"));
            }
        } finally {
            setLoading(false);
        }
    };


    const fillByMinDeposit = () => {
        validation(new BN(minDeposit).decimalPlaces(precision))
    };

    const fillByMAxDeposit = () => {
        validation(new BN(maxDeposit).decimalPlaces(precision))
    };



    const inputChangeHandler = (value) => {
        validation(parsePriceString(value))



    }

    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return <Trans
            i18nKey="Deposit.depositAmount"
            values={{
                amount: amount?.value?.toFormat(),
                currency: getCurrencyNameOrAlias(global?.currencies[gateway?.currencySymbol], language),
            }}
        />
    }

    if (!useIpg) {
        return <div className={`my-10 text-blue`}>
            {t("soon")}
        </div>
    }

    return (
        <div className={`width-100 column jc-between ai-center min-height-100 mt-4`}>


            <div className={`width-100 column jc-center ai-center mb-10`}>
                <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-4 py-1 my-1 position-relative `} onClick={() =>fillByMinDeposit()}>
                    <span className={`width-40`}>{t("Deposit.minDeposit")}</span>
                    <div className={`width-60 row jc-end ai-center`}>
                        <span className={`fs-02 font-weight-bold`}>{new BN(minDeposit)?.decimalPlaces(precision).toFormat()}</span>
                        <span className={`${classes.space}`}>{getCurrencyNameOrAlias(global?.currencies[gateway?.currencySymbol], language)}</span>
                    </div>
                </div>
                <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-4 py-1 my-1 position-relative `} onClick={() =>fillByMAxDeposit()}>
                    <span className={`width-40`}>{t("Deposit.maxDeposit")}</span>
                    <div className={`width-60 row jc-end ai-center`}>
                        <span className={`fs-02 font-weight-bold`}>{new BN(maxDeposit)?.decimalPlaces(precision).toFormat()}</span>
                        <span className={`${classes.space}`}>{getCurrencyNameOrAlias(global?.currencies[gateway?.currencySymbol], language)}</span>
                    </div>
                </div>

                <NumberInput
                    value={amount.value.toFormat()}
                    type="text"
                    label={t('amount')}
                    //placeholder={t('Login.mobilePh')}

                    //maxLength="10"

                    onchange={(e) => inputChangeHandler(e.target.value)}
                    maxDecimal={precision}
                    maxLength="30"
                    alert={amount.error}
                    inputClass={`width-85 my-1`}
                    icon={
                        <span>{gateway?.currencySymbol}</span>
                    }
                />
            </div>

            { ksData?.kyc_status !== "Approved" && <div className={`width-86 rounded-8 row jc-between ai-center ${classes.deniedBox} text-red px-4 py-1 my-1 position-relative `}>
                <Trans
                    i18nKey="Deposit.ipgDepositAuthenticationRequired"
                    values={{
                        method: t("TransferMethod." + gateway?.transferMethod),
                    }}/>
            </div>}

            <Button
                type="button"
                buttonClass={`${classes.thisButton} width-86 cursor-pointer rounded-100-p mt-2`}
                buttonTitle={buttonTitleHandler()}
                disabled={ loading || amount.error.length > 0 || new BN(amount?.value).isLessThan(new BN(minDeposit)) || ksData?.kyc_status !== "Approved"}
                onClick={()=>submit()}
            />

        </div>
    );
};

export default IPG;
