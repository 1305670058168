import React from 'react';
import classes from './TextInput.module.css'
import Icons from "../Icon/Icons";
import DatePicker from "react-multi-date-picker";
import { calendarsMap } from "../../utils/calendarsMap";



import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import useGlobal from "../../Hooks/useGlobal";

const TextInput = ({inputClass, onchange, label, subLabel, innerRef, textarea, value, icon, alerts, select, selectType, labelFor, datePicker, autoComplete = "off", ...props }) => {

    const {global, setGlobal} = useGlobal();

    const theme = window.env.REACT_APP_DEFAULT_THEME



    const selectClickHandler = () => {
        setGlobal({
            ...global,
            activeActionSheet: {
                menu: false,
                select: true,
            },
            selectType,
        })
    }


    return (
       <div className={`${inputClass}`}>
           <div className={`${classes.inputGroup} ${alerts?.length !== 0 && classes.hasError}`}
                onClick={()=> select ? selectClickHandler() : "" }
           >

               { icon && <i className={`${classes.icon}`}>{icon}</i>}

               {textarea ? <textarea
                   ref={innerRef}
                   rows={1}
                   onChange={onchange}
                   value={value}
                   {...props}
               /> : datePicker ?
                   <DatePicker
                       /*dataPanelPosition={"Bottom"}*/
                       className={`${theme === "DARK" && "bg-dark"}`}
                       locale={calendarsMap[global?.datePickerLocale]}
                       calendar={calendarsMap[global?.calendar]}
                       onChange={onchange}
                       render={<input className={`${classes.datePicker} ${classes.input} direction-${global?.direction}`} readOnly={true}/>}
                       {...props}
                   >
                   </DatePicker> :
               <input
                   onChange={onchange}
                   value={value}
                   autoComplete={autoComplete ? autoComplete.toString() : "off"}
                   className={`${classes.input}`}
                   {...props}
               />}

               {select && <Icons
                   iconName="icon-down-open flex fs-01"
                   iconClass={`cursor-pointer ${classes.select} position-absolute`}
                   /*onClick={() => selectClickHandler()}*/
               />}



               <label className={value && classes.hasValue} htmlFor={labelFor}>{label}</label>

               {/*{alerts?.length !== 0 && <Icons iconName="icon-info-circled flex fs-05 text-red" iconClass={`${classes.thisIcon}`}/>}*/}
           </div>
           {subLabel && <span className={`column fs-0-9 mt-05`}>
               {subLabel}
           </span>}
           <div className={`column fs-0-8 text-red mt-05`}>
               {alerts?.map((alert, index) => <span key={index} className={`pr-05 `}>{alert}</span>)}
           </div>
       </div>


    );
};

export default TextInput;
