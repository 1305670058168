import arrow from "./src/arrow.svg"
import lightArrow from "./src/lightArrow.svg"
import linearLoading from "./src/linear-loading.gif"
import spinnerLoading from "./src/spinnerLoading.gif"
import dotsLoading from "./src/dotsLoading.gif"
import bug from "./src/bug.gif"
import notFound from "./src/notFound.gif"
import unauthorized from "./src/unauthorized.gif"
import upload from "./src/upload.gif"
import user from "./src/user.gif"
import giftClose from "./src/giftClose.gif"
import giftOpen from "./src/giftOpen.gif"
import approve from "./src/approve.svg";
import pending from "./src/pending.svg";
import block from "./src/block.svg";
import reject from "./src/reject.svg";
import file from "./src/file.gif";
import inbox from "./src/inbox.gif";
import sending from "./src/sending.gif";
import LoadConfigError from "./src/LoadConfigError.gif";
import IZB from "./src/bank-logo/BAnk-logo_IranZamin-s.svg";
import AYANDE from "./src/bank-logo/BAnk-logo_Ayandeh-s.svg";
import SHAHR from "./src/bank-logo/BAnk-logo_Shahr-s.svg";
import DEY from "./src/bank-logo/BAnk-logo_Dey-s.svg";
import SEPAH from "./src/bank-logo/BAnk-logo_Sepah-s.svg";
import EGHTESADNOVIN from "./src/bank-logo/BAnk-logo_EghtesanNovin-s.svg";
import KARAFARIN from "./src/bank-logo/BAnk-logo_KarAfarin-s.svg";
import KESHAVRZI from "./src/bank-logo/BAnk-logo_Keshavarzi-s.svg";
import MASKAN from "./src/bank-logo/BAnk-logo_Maskan-s.svg";
import MIRAN from "./src/bank-logo/BAnk-logo_MehrIran-s.svg";
import MELI from "./src/bank-logo/BAnk-logo_Meli-s.svg";
import MELAT from "./src/bank-logo/BAnk-logo_Melat-s.svg";
import PARSIAN from "./src/bank-logo/BAnk-logo_Parsian-s.svg";
import PASARGAD from "./src/bank-logo/BAnk-logo_Pasargad-s.svg";
import POST from "./src/bank-logo/BAnk-logo_PostBank-s.svg";
import REFAH from "./src/bank-logo/BAnk-logo_Refah-s.svg";
import SADERAT from "./src/bank-logo/BAnk-logo_Saderat-s.svg";
import SAMAN from "./src/bank-logo/BAnk-logo_Saman-s.svg";
import SANATOMADAN from "./src/bank-logo/BAnk-logo_SanatVaMadan-s.svg";
import SINA from "./src/bank-logo/BAnk-logo_Sina-s.svg";
import TEJARAT from "./src/bank-logo/BAnk-logo_Tejarat-s.svg";
import TOSEOTAAVON from "./src/bank-logo/BAnk-logo_ToseTaavon-s.svg";
import GARDESHGARI from "./src/bank-logo/BAnk-logo_Gardeshgari-s.svg";
import SARMAYEH from "./src/bank-logo/BAnk-logo_Sarmaye-s.svg";
import NOR from "./src/bank-logo/BAnk-logo_MoaseseEtebariNor-s.svg";
import KHAVARMIANE from "./src/bank-logo/BAnk-logo_Khavarmiane-s.svg";
import ETC from "./src/bank-logo/BAnk-logo_Etc.svg";



export const images = {
    arrow:arrow,
    lightArrow:lightArrow,
    linearLoading:linearLoading,
    spinnerLoading:spinnerLoading,
    dotsLoading:dotsLoading,
    bug:bug,
    notFound:notFound,
    unauthorized:unauthorized,
    upload:upload,
    user:user,
    giftClose:giftClose,
    giftOpen:giftOpen,
    sending:sending,
    LoadConfigError:LoadConfigError,
    Approved: approve,
    Rejected: reject,
    Pending: pending,
    Blocked: block,
    file: file,
    inbox: inbox,
    IZB: IZB,
    AYANDE: AYANDE,
    SHAHR: SHAHR,
    DEY: DEY,
    SEPAH: SEPAH,
    EGHTESADNOVIN: EGHTESADNOVIN,
    KARAFARIN: KARAFARIN,
    KESHAVRZI: KESHAVRZI,
    MASKAN: MASKAN,
    MIRAN: MIRAN,
    MELI: MELI,
    MELAT: MELAT,
    PARSIAN: PARSIAN,
    PASARGAD: PASARGAD,
    POST: POST,
    REFAH: REFAH,
    SADERAT: SADERAT,
    SAMAN: SAMAN,
    SANATOMADAN: SANATOMADAN,
    SINA: SINA,
    TEJARAT: TEJARAT,
    TOSEOTAAVON: TOSEOTAAVON,
    GARDESHGARI: GARDESHGARI,
    SARMAYEH: SARMAYEH,
    NOR: NOR,
    KHAVARMIANE: KHAVARMIANE,
    ETC: ETC,

};
