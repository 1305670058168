import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";

export const useGetGateways = (currency = "") => {
    const axiosPrivate = useAxiosPrivate();

    return useQuery(
        ['gateways', currency],
        async () => {
            const { data } = await axiosPrivate.get(`/api/v1/gateways/${currency}`);
            return data?.data;
        },
        {
            staleTime: 5 * 60 * 1000,
            enabled: !!currency,
        }
    );
};
