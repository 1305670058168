import React, {useEffect, useState} from 'react';
import classes from './Login.module.css'
import {images} from "../../../../assets/images/images";
import Button from "../../../../components/Button/Button";
import {requestOTP} from "../../../../api/auth";
import * as RoutesName from "../../../../Routes/routes";
import toast from "react-hot-toast";
import Loading from "../../../../components/Loading/Loading";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useAuth from "../../../../Hooks/useAuth";
import useGlobal from "../../../../Hooks/useGlobal";
import { PhoneNumberUtil } from 'google-libphonenumber';
import {defaultCountries, parseCountry, PhoneInput} from 'react-international-phone';
import 'react-international-phone/style.css';
import {toAbsoluteUrl} from "../../../../utils/utils";


const PhoneLogin = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {auth, setAuth} = useAuth();

    const {global, setGlobal} = useGlobal();

    const [loading, setLoading] = useState(false)

    const [phone, setPhone] = useState('');


    useEffect(()=>{
        if (auth?.token) navigate(RoutesName.Home)
    },[auth])

    const phoneUtil = PhoneNumberUtil.getInstance();

    const isPhoneValid = (phone) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (error) {
            return false;
        }
    };

    const isValid = isPhoneValid(phone);

    const defaultCountry = global?.defaultCountry?.toLowerCase()
    const validCountry = JSON.stringify(global?.countries)?.toLowerCase()

    const submit = async (e) => {
        e.preventDefault();

        /*if (!isValid) return toast.error(t("Login.phoneNotValid"))*/

        setLoading(true)

        requestOTP(phone)
            .then(async (res) => {

                setAuth({...auth,
                    mobile: phone,
                })

                setGlobal({...global,
                    otpLock: new Date().getTime() + 2 * 60 * 1000,
                })

                navigate(RoutesName.Otp)

            }).catch(err => {

            setAuth({...auth,
                mobile: phone,
            })

            if (err?.response?.data?.data?.needPassword) {
                navigate(RoutesName.Password)
            }
            else {
                toast.error(t("serverError"))
            }

        }).finally(() => {

            setLoading(false)

        })

    }

    const countries = defaultCountries.filter((country) => {
        const { iso2 } = parseCountry(country);
        return validCountry.includes(iso2);
    });

    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('Login.title')
    }


    return (
        <form onSubmit={(e)=>submit(e)} className={`${classes.container} height-100 column jc-between ai-center py-3 px-5`}>

            <div className={`column jc-center ai-center width-100 mt-1`}>
                <img src={toAbsoluteUrl('/assets/logo/lightLogo.svg')} className={`${classes.logo}`} alt=""/>
                <span className={`fs-02 mt-3 font-weight-bold`}>{t("Login.description")}</span>
            </div>




            <div className={`column width-70 jc-center ai-start mb-10`}>

                <div className={`width-100 row jc-between ai-center`}>
                    <span className={`mb-2`}>{t('mobile')}</span>
                    {/*<Icons
                        iconName={`${isValid ? 'icon-ok-circle text-green'  : 'icon-cancel-circled text-red'} fs-02 flex `}
                        iconClass={`toastIcon cursor-pointer`}
                    />*/}
                </div>


                <div className={`width-100 direction-ltr jc-center ai-center ${isValid && classes.valid}`}>
                    <PhoneInput
                        defaultCountry={defaultCountry}
                        forceDialCode
                        value={phone}
                        countries={countries}
                        onChange={(phone) => setPhone(phone)}
                    />
                </div>

                {(!isValid && phone?.length >= 5) && <div className={`text-red mt-1`}>{t("Login.phoneNotValid")}</div>}
            </div>



            <Button
                type="submit"
                buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                buttonTitle={buttonTitleHandler()}
                disabled={!isValid}
            />

        </form>
    );
};

export default PhoneLogin;
