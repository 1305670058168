import React from 'react';
import Icons from "../../../../../../components/Icon/Icons";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";
import useAuth from "../../../../../../Hooks/useAuth";
import useGlobal from "../../../../../../Hooks/useGlobal";
import {isBrowser} from "react-device-detect";
import {useGetUserInfo} from "../../../../../../query";
import classes from "../../Header.module.css"
import {useTranslation} from "react-i18next";

const HeaderBuilder = ({lead, content, after}) => {

    const navigate = useNavigate();

    const {t} = useTranslation();

    const {data} = useGetUserInfo()
    const hasChatNotif = data?.notification?.chat

    const {global, setGlobal} = useGlobal();

    const direction = global?.direction


    const openGiftActionSheetHandler = () => {
        setGlobal({
            ...global,
            activeActionSheet: {
                menu: false,
                select: true,
            },
            selectType: "gift",
        })
    }

    const leadHandler = () => {
        if (lead === "back") return <Icons
            iconName={`${direction === 'ltr' ? 'icon-left-open' : 'icon-right-open'} flex`}
            iconClass={`cursor-pointer`}
            onClick={()=>navigate(-1)}
        />

        if (lead === "menu" && !isBrowser) return <Icons
            iconName="icon-menu flex"
            iconClass={`cursor-pointer`}
            //onClick={()=>setActive(prevstate => !prevstate)}
            //onClick={() => dispatch(activeActionSheet({subMenu: true}))}
            onClick={() => setGlobal({
                ...global,
                activeActionSheet: {
                    menu: true,
                    select: false,
                },
            }) }

        />
        if (lead === "home") return <Icons
            iconName={`${direction === 'ltr' ? 'icon-left-open' : 'icon-right-open'} flex`}
            iconClass={`cursor-pointer`}
            onClick={()=>{
                setGlobal({
                    ...global,
                    activeActionSheet: {
                        menu: true,
                        select: false,
                    },
                    selected_deposit_coin: null,
                    selected_deposit_network: null,
                    selected_exchange_base: null,
                    selected_exchange_quote: null,
                    selected_withdrawal_coin: null,
                    selected_withdrawal_network: null,
                    selected_deposit_sheba: null,
                    selected_deposit_card: null,
                })
                navigate(RoutesName.Home)
            }}
        />
        return <></>

    }
    const contentHandler = () => {

    }
    const afterHandler = () => {

        if (after === "chat") return <>
            <div className={`position-relative`}>
                <Icons
                    iconName="icon-chat flex fs-04"
                    iconClass={`cursor-pointer`}
                    onClick={()=>navigate(RoutesName.Chat)}
                />
                {hasChatNotif && <span className={`${classes.hintIcon}`}/>}
            </div>
        </>
        if (after === "gift") return <>



            {global?.voucherSupport && <div className={`flex jc-center ai-center`}
                                            data-html={true}
                                            data-place="bottom"
                                            data-effect="float"
                                            data-tooltip-place="bottom"
                                            data-tooltip-id="polban-tooltip"
                                            data-tooltip-float={true}
                                            data-tooltip-html={`<span class="column jc-between">${t("giftCode")}</span>`}
            >
                <Icons
                    iconName="icon-gift flex fs-04"
                    iconClass={`cursor-pointer ml-05`}
                    onClick={()=>openGiftActionSheetHandler()}
                />
            </div>}

            <div className={`position-relative flex jc-center ai-center`}
                 data-html={true}
                 data-place="bottom"
                 data-effect="float"
                 data-tooltip-place="bottom"
                 data-tooltip-id="polban-tooltip"
                 data-tooltip-float={true}
                 data-tooltip-html={`<span class="column jc-between">${t("Chat.title")}</span>`}
            >
                <Icons
                    iconName="icon-chat flex fs-04"
                    iconClass={`cursor-pointer`}
                    onClick={()=>navigate(RoutesName.Chat)}
                />
                {hasChatNotif && <span className={`${classes.hintIcon}`}/>}
            </div>
        </>
        return <></>

    }

    return (
        <div className={`width-100 row flex jc-center ai-center`}>
            <div className={`width-20 flex jc-start ai-center fs-03`}>{leadHandler()}</div>
            <div className={`width-60 flex jc-center ai-center font-weight-bold fs-02`}>{content}</div>
            <div className={`width-20 flex jc-end ai-center fs-03`}>{afterHandler()}</div>
        </div>
    );
};

export default HeaderBuilder;
