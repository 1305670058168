import React, {lazy, Suspense,  useEffect, useState} from 'react';
import classes from "./OffChainDeposit.module.css"
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import useAuth from "../../../../../../Hooks/useAuth";
import {useGetGateways, useGetTerminals, useGetUserWallet} from "../../../../../../query";
import TextInput from "../../../../../../components/TextInput/TextInput";
import SHEBA from "../SHEBA/SHEBA";
import CARD from "../CARD/CARD";
import IPG from "../IPG/IPG";


const OffChainDeposit = () => {

    const {t} = useTranslation();
    const {global, setGlobal} = useGlobal();
    const {auth} = useAuth();
    const gateway = global?.selected_deposit_network
    const transferMethod = gateway?.transferMethod

    const currency = global?.selected_deposit_coin

    const {data: terminalsData, isLoading: terminalsIsLoading, error} = useGetTerminals(gateway?.gatewayUuid)

    useEffect(() => {
        let newGlobal = {...global}


        if (terminalsData) {
            const firstActiveTerminal = terminalsData.find(
                terminal => terminal.active
            );
            if (firstActiveTerminal) {
                newGlobal = {
                    ...newGlobal,
                    /*selected_deposit_sheba: firstActiveTerminal,*/
                    ["selected_deposit_" + gateway?.transferMethod?.toLowerCase()]: firstActiveTerminal
                }
            }
        }

        setGlobal(newGlobal)
    }, [terminalsData, !terminalsIsLoading]);



    const [input, setInput] = useState({
        transferMethod: {value: "", error: []},
    });

    /*const {data, isLoading, error} = useGetGateways(currency)*/

    /*useEffect(() => {

        console.log("data", data)

        if (data) {
            let newInputData = {...input}
            let newGlobal = {...global}
            console.log("in use")
            if (!gateway) {
            }
            const firstValidGateway = Object.values(data).find(
                item => item.depositAllowed
            );
            if (firstValidGateway) {
                newInputData = {...newInputData,
                    transferMethod : { value: firstValidGateway?.transferMethod, error: []},
                }
                newGlobal = {
                    ...newGlobal,
                    selected_deposit_network: firstValidGateway,
                }
            }
            setInput(newInputData)
            setGlobal(newGlobal)
            if (gateway?.transferMethod !== firstValidGateway?.transferMethod) {
                console.log("result")
            }
            console.log("firstValidGateway", firstValidGateway)
        }
    }, [data, gateway, !isLoading]);*/



    /*       setInput(prevInput => {
                    if (prevInput.transferMethod?.value) {
                        return prevInput;
                    }

                    const firstValidGateway = Object.values(data).find(
                        item => item.depositAllowed
                    );

                    if (firstValidGateway) {
                        global.selected_deposit_network = firstValidGateway;
                        return {
                            ...prevInput,
                            transferMethod: { value: firstValidGateway?.transferMethod, error: [] }
                        };
                    }

                    return prevInput;
                });*/

    /*useEffect(() => {

        if (data) {
            const allowedDeposits = Object.values(data).filter(item => item.depositAllowed);
            let newInputData = {...input}
            if (allowedDeposits.length === 1) {

                console.log("in log")


                newInputData = {...newInputData,
                    transferMethod : { value: allowedDeposits[0]?.transferMethod, error: []},
                }

                global.selected_deposit_network = allowedDeposits[0];
            }
            setInput(newInputData)
        }
    }, [data]);*/


   /* const [DynamicComponent, setDynamicComponent] = useState(null);
    const [hasError, setHasError] = useState(false);


    useEffect(() => {
        if (!transferMethod) return;
        setHasError(false);

        import(`../${transferMethod}/${transferMethod}.js`)
            .then((module) => {
                setDynamicComponent(() => lazy(() => Promise.resolve({ default: module.default })));
                setHasError(false);
            })
            .catch((error) => {
                console.warn(`Component not found for method: ${transferMethod}`);
                setHasError(true);
                setDynamicComponent(null);
            });
    }, [transferMethod]);*/


    useEffect(()=>{
        let newInputData = {...input}
        if (gateway) {
            newInputData = {...newInputData,
                transferMethod : { value: gateway?.transferMethod, error: []},
            }
        }
        setInput(newInputData)
    },[global?.selectType, gateway])


    /*if (isLoading) return <div className={`width-85`}>
        <div className={`${classes.loading} width-100 rounded-8 my-1`}/>
    </div>*/

    const content = () => {
      if (transferMethod === "SHEBA") {
          return <SHEBA/>
      }
      if (transferMethod === "CARD") {
          return <CARD/>
      }
      if (transferMethod === "IPG") {
          return <IPG/>
      }
    }


    return (
        <>
            <TextInput
                value={input.transferMethod.value ? t("TransferMethod." + input.transferMethod.value) : input.transferMethod.value}
                // value={t('currency.'+ input.asset.value)}
                type="text"
                label={t('transferMethod')}
                //placeholder={t('Login.mobilePh')}
                data-name="transferMethod"
                data-type="input"
                data-min={2}
                //maxLength="10"
                /*onchange={(e) => inputHandler(e)}*/
                alerts={input.transferMethod.error}
                inputClass={`width-85 my-1`}
                select={true}
                readOnly={true}
                selectType="deposit_network"
            />

            {

                content()
            }
            {/*{hasError ? (
                <span className={`my-10`}>{t("noData")}</span>
            ) : DynamicComponent ? (
                <Suspense fallback={<>
                    <div className={`${classes.rectangle2} width-85 rounded-8 my-1`}/>
                    <div className={`${classes.rectangle2} width-85 rounded-8 my-1`}/>
                    <div className={`${classes.rectangle2} width-85 rounded-8 my-1`}/>
                </>}>
                    <DynamicComponent />
                </Suspense>
            ) : (
                <p></p>
            )}*/}
        </>
    );
};

export default OffChainDeposit;
