import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import TextInput from "../../../../../../components/TextInput/TextInput";
import {useGetUserWallet} from "../../../../../../query";
import {BN, getCurrencyNameOrAlias, parsePriceString} from "../../../../../../utils/utils";
import {sendWithdrawReq} from "../../../../../../api/exchange";
import toast from "react-hot-toast";
import i18next from "i18next";
import Loading from "../../../../../../components/Loading/Loading";
import classes from "./OnChainWithdraw.module.css";
import NumberInput from "../../../../../../components/NumberInput/NumberInput";
import Icon from "../../../../../../components/Icon/Icons";
import Button from "../../../../../../components/Button/Button";
import useAuth from "../../../../../../Hooks/useAuth";

const OnChainWithdraw = () => {

    const {t} = useTranslation();
    const {global} = useGlobal();
    const {auth} = useAuth();

    const language = global?.language
    const gateway = global?.selected_withdrawal_network

    const [loading, setLoading] = useState(false)

    const [input, setInput] = useState({
        network: {value: "", error: []},
        blockchainAddress: {value: "", error: []},
        txPassword: {value: "", error: []},
    });

    const [amount, setAmount] = useState({value: new BN(0), error: []});

    const {data: userWalletData, isLoading: userWalletIsLoading, error: userWalletError, refetch:refetchUserWallet} = useGetUserWallet()

    const [isInputVisible, setIsInputVisible] = useState({
        password: false,
    });

    useEffect(()=>{
        let newInputData = {...input}
        if (gateway) {
            newInputData = {...newInputData,
                network : { value:  gateway?.chain?.toUpperCase() + " - " + gateway?.implementationSymbol, error: []},
            }
        }
        else {
            newInputData = {...newInputData,
                network : { value: "", error: []},
            }
        }
        setInput(newInputData)
    },[global?.selectType])



    const precision = global?.currencies[gateway?.currencySymbol]?.precision
    const freeWallet = !userWalletIsLoading && userWalletData[gateway?.currencySymbol]?.balance || 0

    const minWithdraw = gateway?.withdrawMin
    const maxWithdraw = gateway?.withdrawMax
    const withdrawFee = gateway?.withdrawFee
    /*const withdrawEnabled = !isLoading && data?.currencyImpData?.implementations[0]?.withdrawEnabled*/
    const withdrawEnabled = gateway?.withdrawAllowed


    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setInput(prevState)
    }

    const isFormValid = () => {
        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }

    const submit = async () => {
        if (!isFormValid()) return false;

        setLoading(true)

        const withdrawRequestData = {
            "destSymbol": gateway?.currencySymbol.toUpperCase(),
            "destAddress": input.blockchainAddress.value,
            "gatewayUuid": gateway?.gatewayUuid,
            "amount": amount?.value,
            "password": input.txPassword.value,
        }

        sendWithdrawReq(withdrawRequestData, auth?.token)
            .then(async (res) => {


                toast.success(<Trans
                    i18nKey="Withdrawal.success"
                    values={{
                        asset: getCurrencyNameOrAlias(global?.currencies[gateway?.currencySymbol], language),
                        amount: amount.value,
                    }}
                />);

                refetchUserWallet()
                /*refetchTransactions()*/

                setInput({
                    ...input,
                    blockchainAddress: {value: "", error: []},
                    txPassword: {value: "", error: []},
                })

                setAmount({value: new BN(0), error: []})


            }).catch(err => {


            if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                toast.error(t("ErrorMessages."+ err?.response?.data?.message))
            }
            else {
                toast.error(t("serverError"))
            }


        }).finally(() => {

            setLoading(false)

        })


    }

    const validation = (value) => {
        const newAmount = { ...amount, error: [] };
        const bnValue = new BN(value);
        const bnFreeWallet = new BN(freeWallet);
        const bnWithdrawFee = new BN(withdrawFee);
        const bnMinWithdraw = new BN(minWithdraw);
        const bnMaxWithdraw = new BN(maxWithdraw);
        const bnNetAmount = bnValue.minus(bnWithdrawFee);

        if (bnValue.isGreaterThan(bnFreeWallet)) {
            newAmount.error = t('Withdrawal.noInventory');
        }

        else if (bnMinWithdraw.isGreaterThan(bnWithdrawFee) && bnValue.isLessThan(bnMinWithdraw)) {
            newAmount.error = t('Withdrawal.allowableWithdraw');
        }

        else if (bnValue.isGreaterThan(bnMaxWithdraw)) {
            newAmount.error = t('Withdrawal.maxLimitExceeded');
        }

        else if (bnNetAmount.isLessThanOrEqualTo(0)) {
            newAmount.error = t('Withdrawal.zeroReceiveAmountwithdrawFee');
        }

        newAmount.value = bnValue;
        setAmount(newAmount);
    };

    const fillByWallet = () => {
        validation(new BN(freeWallet).decimalPlaces(precision));
    };

    const fillByMinWithdraw = () => {
        validation(new BN(minWithdraw).decimalPlaces(precision));
    };

    const fillByMaxWithdraw = () => {
        validation(new BN(maxWithdraw).decimalPlaces(precision));
    };

    const inputChangeHandler = (value) => {
        validation(parsePriceString(value));
    };


    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('submit')
    }


    return (
        <>
            <TextInput
                value={input.network.value}
                // value={t('currency.'+ input.asset.value)}
                type="text"
                label={t('network')}
                //placeholder={t('Login.mobilePh')}
                data-name="network"
                data-type="input"
                data-min={2}
                //maxLength="10"
                /*onchange={(e) => inputHandler(e)}*/
                alerts={input.network.error}
                inputClass={`width-85 my-1`}
                select={true}
                readOnly={true}
                selectType="withdrawal_network"
            />

            {
                gateway && <>

                    <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-4 py-1 my-1 position-relative `} onClick={() =>fillByWallet()}>
                        <span className={`width-40`}>{t("Withdrawal.availableWithdrawalBalance")}</span>
                        <div className={`width-60 row jc-end ai-center`}>
                            <span className={`fs-02 font-weight-bold`}>{new BN(freeWallet)?.decimalPlaces(precision).toFormat()}</span>
                            <span className={`${classes.space}`}>{getCurrencyNameOrAlias(global?.currencies[gateway?.currencySymbol], language)}</span>
                        </div>
                    </div>
                    <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-4 py-1 my-1 position-relative `} onClick={() =>fillByMinWithdraw()}>
                        <span className={`width-40`}>{t("Withdrawal.minWithdrawal")}</span>
                        <div className={`width-60 row jc-end ai-center`}>
                            <span className={`fs-02 font-weight-bold`}>{new BN(minWithdraw)?.decimalPlaces(precision).toFormat()}</span>
                            <span className={`${classes.space}`}>{getCurrencyNameOrAlias(global?.currencies[gateway?.currencySymbol], language)}</span>
                        </div>
                    </div>
                    <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-4 py-1 my-1 position-relative `} onClick={() =>fillByMaxWithdraw()}>
                        <span className={`width-40`}>{t("Withdrawal.maxWithdrawal")}</span>
                        <div className={`width-60 row jc-end ai-center`}>
                            <span className={`fs-02 font-weight-bold`}>{new BN(maxWithdraw)?.decimalPlaces(precision).toFormat()}</span>
                            <span className={`${classes.space}`}>{getCurrencyNameOrAlias(global?.currencies[gateway?.currencySymbol], language)}</span>
                        </div>
                    </div>
                    <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-4 py-1 mt-1 mb-4 position-relative `}>
                        <span className={`width-40`}>{t("commission")}</span>
                        <div className={`width-60 row jc-end ai-center`}>
                            <span className={`fs-02 font-weight-bold`}>{new BN(withdrawFee)?.decimalPlaces(precision).toFormat()}</span>
                            <span className={`${classes.space}`}>{getCurrencyNameOrAlias(global?.currencies[gateway?.currencySymbol], language)}</span>
                        </div>
                    </div>

                    <NumberInput
                        value={amount.value.toFormat()}
                        type="text"
                        label={t('amount')}
                        //placeholder={t('Login.mobilePh')}

                        //maxLength="10"

                        onchange={(e) => inputChangeHandler(e.target.value)}
                        maxDecimal={precision}
                        maxLength="20"
                        alert={amount.error}
                        inputClass={`width-86 my-1`}
                        icon={
                            <span>{gateway?.currencySymbol}</span>
                        }
                    />


                    <input type="text" name="fake-user" style={{ display: "none" }} autoComplete="off" />

                    <TextInput
                        value={input.blockchainAddress.value}
                        label={t('blockchainAddress')}
                        type="text"
                        id="custom-blockchain"
                        labelFor="custom-blockchain"
                        name="randomField123"
                        autoComplete="new-password"
                        readOnly
                        onFocus={(e) => {
                            e.target.removeAttribute('readonly');
                            e.target.setAttribute("autocomplete", "off");
                        }}
                        data-name="blockchainAddress"
                        data-type="input"
                        data-min={2}
                        onChange={(e) => inputHandler(e)}
                        alerts={input.blockchainAddress.error}
                        inputClass={`width-86 my-1 numeric-ltr`}
                    />



                    <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-4 py-1 my-3 position-relative `}>
                        <span className={`width-40`}>{t("Withdrawal.yourReceive")}</span>
                        <div className={`width-60 row jc-end ai-center`}>
                            <span className={`fs-02 font-weight-bold`}>
                                {(new BN(amount.value).minus(withdrawFee).isGreaterThan(0) && new BN(freeWallet).isGreaterThan(0) && new BN(amount.value).isLessThan(new BN(freeWallet))) ? new BN(amount.value).minus(withdrawFee).decimalPlaces(precision).toFormat() : 0}
                            </span>
                            <span className={`${classes.space}`}>{getCurrencyNameOrAlias(global?.currencies[gateway?.currencySymbol], language)}</span>
                        </div>
                    </div>

                    <TextInput
                        value={input.txPassword.value}
                        label={t('txPassword')}
                        /*autoComplete={false}*/

                        id="txPassword"
                        labelFor="txPassword"
                        //placeholder={t('Login.mobilePh')}
                        data-name="txPassword"
                        data-type="input"
                        data-min={8}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.txPassword.error}
                        inputClass={`width-86 my-2`}
                        type={isInputVisible.password ? "text" : "password"}
                        icon={
                            <Icon
                                iconName={`${isInputVisible.password ? ' icon-eye' : 'icon-eye-off'} fs-03 flex cursor-pointer hover-text`}
                                onClick={() => setIsInputVisible({
                                    ...isInputVisible,
                                    password: !isInputVisible.password
                                })}
                            />
                        }
                    />

                    <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-4 py-1 mt-2 mb-3 position-relative `}>
                        {t("Withdrawal.blockchainAddressHint")}
                    </div>

                    <Button
                        type="button"
                        buttonClass={`${classes.thisButton} width-86 cursor-pointer rounded-100-p my-1`}
                        buttonTitle={buttonTitleHandler()}
                        disabled={!(new BN(amount.value).minus(withdrawFee).isGreaterThan(0)) || new BN(amount.value).isGreaterThan(freeWallet) || input?.blockchainAddress.value.length <= 0 || loading || amount.error.length > 0}
                        onClick={()=>submit()}
                    />

                </>
            }

        </>
    );
};

export default OnChainWithdraw;
