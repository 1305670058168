import {useQuery} from "@tanstack/react-query";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import useAuth from "../../Hooks/useAuth";

export const useGetAbouUs = () => {

    const axiosPrivate =  useAxiosPrivate()

    return useQuery(
        ['about-us'],
        async () => {
            const {data} = await axiosPrivate.get(`/api/v1/about-us`)
            return data?.data;
        },
        {
            retry: 1,
            staleTime: 5000,
        });
}