import React from 'react';
import classes from './Guide.module.css'
import Button from "../../../../../../components/Button/Button";
import ScrollBar from "../../../../../../components/ScrollBar";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";
import {useGetKycParams} from "../../../../../../query";
import {images} from "../../../../../../assets/images/images";

const Guide = () => {


    const {t} = useTranslation();
    const navigate = useNavigate();

    const {data, isLoading, error} = useGetKycParams()

    const content = () => {
        if (isLoading) {
            return <div className={`width-100 column jc-center ai-center height-100`}>
                <div className={`${classes.loading} width-100 rounded-8 my-1`}/>
                <div className={`${classes.loading} width-100 rounded-8 my-1`}/>
                <div className={`${classes.loading} width-100 rounded-8 my-1`}/>
            </div>
        }
        if (error) {
            return <div className={`width-100 column jc-center ai-center height-100 my-10`}>
                <span className={`mt-1`}>{t("error")}</span>
            </div>
        }
        if (data?.length <= 0) {
            return <div className={`width-100 column jc-center ai-center height-100 py-10`}>
                <img src={images.file} className={`${classes.image} mb-1`} alt=""/>

                <span className={`mt-1`}>{t("noData")}</span>
            </div>
        }
        return Object.keys(data)?.map((field, index) => <div className={`${classes.documentBox} width-100 rounded-8 column mb-1`} key={index}>
            - {data[field]?.name_fa}
        </div>)


    }

    const submit = async (e) => {
        e.preventDefault();
        //if (!isFormValid()) return false;
        return navigate(RoutesName.UploadDocuments)
    }

    return (
        <ScrollBar>
            <form onSubmit={(e)=>submit(e)} className={`${classes.container} column jc-between ai-center py-3 px-5`}>
                <div className={`width-86 column jc-center ai-center`}>
                    <div className={`rounded-8 flex jc-center ai-start ${classes.noteBox} px-5 py-2 mt-1 mb-3`}>
                        <span className={``}>{t("Authentication.content")}</span>
                    </div>
                    {/*<div className={`rounded-8 flex jc-center ai-start ${classes.noteBox} px-5 py-2 mb-3`}>
                        <span className={``}>{t("Authentication.beforeKycNote")}</span>
                    </div>*/}

                    <p className={`font-weight-bold fs-01 width-100 mb-2`}>{t("Authentication.requiredDocuments")}</p>

                    {content()}

                    {/*{Object.keys(data)?.map((field, index) => <Sample data={data[field]} index={index} key={index} />)}*/}

                </div>

                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-2`}
                    buttonTitle={data?.length <= 0 ? <span className={`text-red`}>
                    {t("Authentication.noKycParamsData")}
                        </span>  :  t('start')}
                    disabled={data?.length <= 0 || isLoading || error}
                />
            </form>
        </ScrollBar>
    );
};

export default Guide;
