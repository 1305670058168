import React from 'react';
import classes from './Symbol.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import useGlobal from "../../../../Hooks/useGlobal";
import ScrollBar from "../../../../components/ScrollBar";
import Coin from "../Home/Module/Coin/Coin";
import {BN, getCurrencyNameOrAlias, ratePrint} from "../../../../utils/utils";
import {useGetKycStatus, useGetUserWallet} from "../../../../query";
import Button from "../../../../components/Button/Button";
import Icons from "../../../../components/Icon/Icons";
import toast from "react-hot-toast";
import * as RoutesName from "../../../../Routes/routes";

const Symbol = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {symbol} = useParams();
    const {global, setGlobal} = useGlobal();

    const direction = global?.direction
    const language = global?.language

    const {data: userWalletData, isLoading: userWalletIsLoading, error: userWalletError, refetch:refetchUserWallet} = useGetUserWallet()




    const asset = global?.currencies[symbol]
    const precision = global?.currencies[symbol]?.precision
    const freeWallet = !userWalletIsLoading && userWalletData[symbol]?.balance || 0
    const withdrawWallet = !userWalletIsLoading && userWalletData[symbol]?.withdraw || 0

    const withdrawAllowed = global?.currencies[symbol]?.withdrawAllowed
    const depositAllowed = global?.currencies[symbol]?.depositAllowed

    const {data} = useGetKycStatus()

    const navigateHandler = (route, item) => {
        if (data?.kyc_status === "Approved") {

            setGlobal({
                ...global,
                [item]: symbol
            })

            return navigate(route)
        }
        else return toast.error(t("doKYC"))
    }
    const navigateHandlerWithoutKyc = (route, item) => {

        setGlobal({
            ...global,
            [item]: symbol
        })

        return navigate(route)
    }

    return (
        <div className={`${classes.container} column jc-center ai-center height-100`}>
            <ScrollBar>


                <div className={`width-86 m-auto column jc-center ai-center rounded-8 pt-3 pb-1`}>
                    <img src={asset?.icon} alt="" className={`${classes.circle} ${classes.icon} width-100 flex jc-center ai-center`}/>

                    <div className={`width-100 column jc-center ai-center mt-1`}>
                        <span className={`${classes.name} font-weight-bold fs-02`}>{getCurrencyNameOrAlias(asset, language)}</span>
                        <span className={``}>{asset?.symbol}</span>
                    </div>
                </div>



                <div className={`width-80 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 m-auto position-relative `}>
                    <span className={`width-40`}>{t("walletBalance")}</span>
                    <div className={`width-60 row jc-end ai-center`}>
                        <span className={`fs-02 font-weight-bold`} onClick={()=>setGlobal({...global, hideUserBalance: !global.hideUserBalance})}>{global?.hideUserBalance ? "*****" : new BN(freeWallet)?.decimalPlaces(precision).toFormat() ?? 0 }</span>
                        <span className={`mr-1`}>{getCurrencyNameOrAlias(asset, language)}</span>
                    </div>
                </div>

                <div className={`my-2`}/>

                <div className={`width-80 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 m-auto position-relative `}>
                    <span className={`width-40`}>{t("walletwithdraw")}</span>
                    <div className={`width-60 row jc-end ai-center`}>
                        <span className={`fs-02 font-weight-bold`} onClick={()=>setGlobal({...global, hideUserBalance: !global.hideUserBalance})}>{global?.hideUserBalance ? "*****" : new BN(withdrawWallet)?.decimalPlaces(precision).toFormat() ?? 0 }</span>
                        <span className={`mr-1`}>{getCurrencyNameOrAlias(asset, language)}</span>
                    </div>
                </div>

                <div className={`width-80 m-auto row jc-between ai-center`}>
                    <Button
                        type="button"
                        buttonClass={`${classes.thisButton} width-32 cursor-pointer rounded-100-p my-3`}
                        buttonTitle={t('Deposit.title')}
                        onClick={()=>navigateHandlerWithoutKyc(RoutesName.Deposit, "selected_deposit_coin")}
                        disabled={!depositAllowed}
                    />
                    <Button
                        type="button"
                        buttonClass={`${classes.thisButton} width-32 cursor-pointer rounded-100-p my-3`}
                        buttonTitle={t('exchange')}
                        onClick={()=>navigateHandler(RoutesName.Exchange, "selected_exchange_base")}
                    />
                    <Button
                        type="button"
                        buttonClass={`${classes.thisButton} width-32 cursor-pointer rounded-100-p my-3`}
                        buttonTitle={t('Withdrawal.title')}
                        onClick={()=>navigateHandler(RoutesName.Withdrawal, "selected_withdrawal_coin")}
                        disabled={!withdrawAllowed}
                    />
                </div>


                <div className={`width-80 m-auto column jc-start ai-start rounded-8`}>
                    <span className={`text-justify`}>{asset?.description}</span>
                </div>
                {/*<div className={`width-86 m-auto column jc-center ai-center rounded-8`}>
                    <iframe src="https://app.exky.io" width={350}  height={500}></iframe>
                </div>*/}




            </ScrollBar>
        </div>
    );
};

export default Symbol;
