import React from 'react';
import classes from './Wallet.module.css';
import {useGetCurrencies, useGetDashboard, useGetUserWallet} from "../../../../../../query";
import Coin from "../Coin/Coin";
import WalletCard from "../WalletCard/WalletCard";
import {Trans, useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import {BN, getCurrencyNameOrAlias} from "../../../../../../utils/utils";

const Wallet = () => {

    const {t} = useTranslation();

    const {global} = useGlobal();

    const {data: userWalletData, isLoading: userWalletIsLoading, error: userWalletError} = useGetUserWallet()



    /*console.log("userWalletData", userWalletData)*/

    const content = () => {
        /* if (isLoading) {
             return <span className={`my-10`}>{t("loading")}</span>
         }*/
        if (userWalletIsLoading) {
            return <div className={`width-100 column jc-center ai-center height-100 my-1`}>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
            </div>
        }
        if (userWalletError) {
            return <span className={`my-10`}>{t("error")}</span>
        }
        if (Object.keys(userWalletData).length <= 0) {
            return <span className={`my-10`}>{t("noData")}</span>
        }
        /*return data?.map( (c , index) =>
            <Coin data={c} key={index}/>
        )*/

        /*const newObject = [];
        Object.keys(data).forEach((key) => {
            newObject.push( <Coin data={data[key]} key={key}/>)
        });
        return newObject;*/

        return <>
            <div className={`width-100 ${classes.header}`}>
                <div className={`width-90 row jc-between ai-center m-auto py-2`}>
                    <span className={`font-weight-bold fs-02`}>{t("walletBalance")}</span>
                </div>
            </div>

            {

                Object.keys(global?.currencies)
                    .filter((key) => {
                        const currency = global?.currencies[key];
                        const userWallet = userWalletData?.[key];

                        if (!currency.isActive && (!userWallet || userWallet.balance === '0')) {
                            return false;
                        }

                        return !currency.isTransitive;
                    })
                    .sort((a, b) => {
                        const currencyA = global?.currencies[a];
                        const currencyB = global?.currencies[b];
                        const userWalletA = userWalletData?.[a];
                        const userWalletB = userWalletData?.[b];


                        const balanceA = new BN(userWalletA?.balance || '0');
                        const balanceB = new BN(userWalletB?.balance || '0');

                        const isZeroA = balanceA.decimalPlaces(currencyA.precision).isZero();
                        const isZeroB = balanceB.decimalPlaces(currencyB.precision).isZero();


                        if (currencyA.isActive === currencyB.isActive) {
                            if (isZeroA === isZeroB) {
                                return 0;
                            }

                            return isZeroA ? 1 : -1;
                        }

                        return currencyA.isActive ? -1 : 1;
                    })
                    .map((key) => {
                        return (
                            <WalletCard
                                data={global?.currencies[key]}
                                wallet={userWalletData[key]}
                                key={key}
                            />
                        );
                    })

            }
        </>




    }

    return (
        <div className={`width-86 ${classes.container} mt-4 mb-4 column jc-center ai-center rounded-8`}>
            {content()}
        </div>
    );
};

export default Wallet;
