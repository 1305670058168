import React, {useEffect, useState} from 'react';
import classes from './CARD.module.css'
import {Trans, useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import {useGetTerminals, useGetUserInfo} from "../../../../../../query";
import toast from "react-hot-toast";
import {BN, getCurrencyNameOrAlias} from "../../../../../../utils/utils";
import TextInput from "../../../../../../components/TextInput/TextInput";
import {isMobile} from "react-device-detect";
import {images} from "../../../../../../assets/images/images";
import Icons from "../../../../../../components/Icon/Icons";


const CARD = () => {

    const {t} = useTranslation();
    const {global, setGlobal} = useGlobal();
    const {data} = useGetUserInfo()
    const language = global?.language
    const gateway = global?.selected_deposit_network
    const terminal = global?.selected_deposit_card
    const precision = global?.currencies[gateway?.currencySymbol]?.precision || 0
    const minDeposit = gateway?.depositMin || 0
    const maxDeposit = gateway?.depositMax || 0

/*
    const {data: terminalsData, isLoading: terminalsIsLoading, error} = useGetTerminals(gateway?.gatewayUuid)
*/

    const [input, setInput] = useState({
        bank: {value: "", error: []},
    });

   /* useEffect(() => {
        let newGlobal = {...global}
        if (terminalsData) {
            setInput(prevInput => {
                if (prevInput.bank?.value) {
                    return prevInput;
                }
                const firstActiveTerminal = terminalsData.find(
                    terminal => terminal.active
                );
                if (firstActiveTerminal) {
                    newGlobal = {
                        ...newGlobal,
                        selected_deposit_card: firstActiveTerminal,
                    }
                    return {
                        ...prevInput,
                        bank: { value: firstActiveTerminal.metaData, error: [] }
                    };
                }
                return prevInput;
            });
        }
        setGlobal(newGlobal)
    }, [terminalsData, terminalsIsLoading, input]);*/

    useEffect(()=>{
        let newInputData = {...input}
        if (terminal) {
            newInputData = {...newInputData,
                bank : { value: terminal?.metaData, error: []},
            }
        }
        setInput(newInputData)
    },[global?.selectType, terminal])

    const copyToClipboard = (e, value) => {
        e.stopPropagation();
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success(t("copy"));
    }

    /*if (terminalsIsLoading) return <div className={`width-85`}>
        <div className={`${classes.loading} width-100 rounded-8 my-1`}/>
    </div>*/


    return (
        <div className={`width-100 column jc-between ai-center min-height-100 mt-4`}>
            <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-4 py-1 my-1 position-relative `}>
                <span className={`width-40`}>{t("Deposit.minDeposit")}</span>
                <div className={`width-60 row jc-end ai-center`}>
                    <span className={`fs-02 font-weight-bold`}>{new BN(minDeposit)?.decimalPlaces(precision).toFormat()}</span>
                    <span className={`${classes.space}`}>{getCurrencyNameOrAlias(global?.currencies[gateway?.currencySymbol], language)}</span>
                </div>
            </div>
            <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-4 py-1 mt-1 mb-3 position-relative `}>
                <span className={`width-40`}>{t("Deposit.maxDeposit")}</span>
                <div className={`width-60 row jc-end ai-center`}>
                    <span className={`fs-02 font-weight-bold`}>{new BN(maxDeposit)?.decimalPlaces(precision).toFormat()}</span>
                    <span className={`${classes.space}`}>{getCurrencyNameOrAlias(global?.currencies[gateway?.currencySymbol], language)}</span>
                </div>
            </div>


            <TextInput
                value={input.bank.value && t("IBAN." + input.bank.value)}
                // value={t('currency.'+ input.asset.value)}
                type="text"
                label={t('Deposit.selectBank')}
                //placeholder={t('Login.mobilePh')}
                data-name="transferMethod"
                data-type="input"
                data-min={2}
                //maxLength="10"
                /*onchange={(e) => inputHandler(e)}*/
                alerts={input.bank.error}
                inputClass={`width-85 my-1`}
                select={true}
                readOnly={true}
                /*selectType="deposit_sheba"*/
                selectType={"deposit_" + gateway?.transferMethod?.toLowerCase()}
            />

            {
                terminal && <>

                    <div className={`${classes.asset} width-85 py-1 ${isMobile ? "px-3" : "px-1"} rounded-8 column`}>

                        <div className={`${classes.logo} width-26`}
                             style={{backgroundImage: `url("${images[terminal?.metaData]}")`}}
                        />



                        <div className={`column ${isMobile ? "mx-2" : "mx-05"}`}>
                            <span className={`font-weight-bold`}>{t("IBAN."+ terminal?.metaData)}</span>

                            <div className={`row jc-between ai-center mt-1`}>
                                <span >{t("Terminal.owner")}:</span>
                                <span className={`font-weight-bold`}>{terminal?.owner}</span>
                            </div>
                            <div className={`row jc-between ai-center`}>
                                <span >{t("Terminal.card")}:</span>
                                <div className={``}>
                                    <span className={`font-weight-bold direction-ltr unicode-bidi-embed`}>{terminal?.identifier.match(new RegExp('.{1,4}', 'g')).join(" - ")}</span>
                                    <Icons
                                        iconName="icon-copy fs-02"
                                        iconClass={`cursor-pointer ${isMobile ? "mr-05" : "mr-025"} hover-text`}
                                        onClick={(e) => copyToClipboard(e, terminal?.identifier)}
                                    />

                                </div>
                            </div>
                            <div className={`width-100 ${classes.divider} my-2`}/>
                            <span>
                                <Trans
                                    i18nKey="Terminal.content"
                                    values={{
                                        method: t("Terminal.card"),
                                        identifier: terminal?.identifier,
                                        owner: terminal?.owner,
                                        name: data?.first_name + " " + data?.last_name,
                                    }}
                                />
                            </span>
                        </div>

                    </div>

                </>
            }

        </div>
    );
};

export default CARD;
