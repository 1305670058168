import React from 'react';
import classes from './TerminalsCard.module.css'
import {useTranslation} from "react-i18next";
import {images} from "../../../../../../assets/images/images";
import useGlobal from "../../../../../../Hooks/useGlobal";
import toast from "react-hot-toast";
import {isMobile} from "react-device-detect";

const TerminalsCard = ({terminal}) => {

    const {t} = useTranslation();

    const isAllowed = terminal?.active

    const {global, setGlobal} = useGlobal();

    const selectedCoinHandler = (terminal) => {
        if (!isAllowed) {
            return toast.error(t("terminalIsNotAllowed"))
        }
        let newGlobal = {...global}
        if (newGlobal) {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                ["selected_" + global?.selectType]: terminal
            }
        }
        else {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                ["selected_" + global?.selectType]: null
            }
        }
        setGlobal(newGlobal)
    }
    return (
        <div className={`${classes.asset} ${!isAllowed && "text-gray"} width-90 row jc-start ai-center py-1 ${isMobile ? "px-3" : "px-1"} rounded-8`} onClick={()=>selectedCoinHandler(terminal)}>

            <div className={`${classes.logo} ${!isAllowed && classes.grayFilter} width-26`}
                 style={{backgroundImage: `url("${images[terminal?.metaData]}")`}}
            />

            <div className={`column mr-2`}>
                <span className={`font-weight-bold`}>{t("IBAN."+ terminal?.metaData)}</span>
                <span className={``}>{terminal?.owner}</span>
            </div>



        </div>
    );
};

export default TerminalsCard;
