import React from 'react';
import classes from './Header.module.css';
import {Route, Routes, useParams} from "react-router-dom";
import * as RoutesName from "../../../../Routes/routes";
import {useTranslation} from "react-i18next";
import HeaderBuilder from "./Module/HeaderBuilder/HeaderBuilder";
import {HistoryRelative, Rules, TransactionsHistory} from "../../../../Routes/routes";
import useGlobal from "../../../../Hooks/useGlobal";
import History from "../../Pages/History/History";
import {getCurrencyNameOrAlias} from "../../../../utils/utils";

const Header = () => {

    const {t} = useTranslation();
    const {symbol} = useParams();
    const {global} = useGlobal();

    const language = global?.language

    return (
        <div className={`${classes.header} height-9 width-100 flex jc-center ai-center px-5 text-white`}>
            <Routes>
                <Route path={RoutesName.Login} element={<HeaderBuilder content={t("Login.title")}/>}/>
                <Route path={RoutesName.PaymentResult} element={<HeaderBuilder content={t("PaymentResult.title")}/>}/>
                <Route path={RoutesName.Otp} element={<HeaderBuilder lead="back" content={t("Otp.title")}/>}/>
                <Route path={RoutesName.Password} element={<HeaderBuilder lead="back" content={t("Password.title")}/>}/>
                <Route path={RoutesName.ForgetPassword} element={<HeaderBuilder lead="back" content={t("forgetPassword")}/>}/>
                <Route path={RoutesName.Register} element={<HeaderBuilder lead="back" content={t("Register.title")}/>}/>
                <Route path={RoutesName.Chat} element={<HeaderBuilder lead="back" content={t("Chat.title")}/>}/>

                <Route path={RoutesName.UploadDocuments} element={<HeaderBuilder lead="back" content={t("Authentication.title")} after="chat"/>}/>
                <Route path={RoutesName.KYCStatus} element={<HeaderBuilder lead="home" content={t("Authentication.title")} after="chat"/>}/>

                <Route path={RoutesName.Home} element={<HeaderBuilder lead="menu" content="" after="gift"/>}/>

                <Route path={RoutesName.Transfer} element={<HeaderBuilder lead="home" content={t("transfer")} after="chat"/>}/>
                <Route path={RoutesName.Exchange} element={<HeaderBuilder lead="home" content={t("exchange")} after="chat"/>}/>
                <Route path={RoutesName.Deposit} element={<HeaderBuilder lead="home" content={t("Deposit.title")} after="chat"/>}/>
                <Route path={RoutesName.Withdrawal} element={<HeaderBuilder lead="home" content={t("Withdrawal.title")} after="chat"/>}/>
                <Route path={RoutesName.Transaction} element={<HeaderBuilder lead="home" content={t("transaction")} after="chat"/>}/>
                <Route path={RoutesName.Accounts} element={<HeaderBuilder lead="home" content={t("LinkedBankAccounts.title")} after="chat"/>}/>
                <Route path={RoutesName.UpdateProfile} element={<HeaderBuilder lead="home" content={t("UpdateProfile.title")} after="chat"/>}/>
                <Route path={RoutesName.ContactUs} element={<HeaderBuilder lead="home" content={t("Contact.title")} after="chat"/>}/>
                <Route path={RoutesName.Rules} element={<HeaderBuilder lead="home" content={t("Rules.title")} after="chat"/>}/>
                <Route path={RoutesName.AbouUs} element={<HeaderBuilder lead="home" content={t("AbouUs.title")} after="chat"/>}/>
                <Route path={RoutesName.TransactionPassword} element={<HeaderBuilder lead="home" content={t("TransactionPassword.title")} after="chat"/>}/>
                <Route path={RoutesName.Authentication} element={<HeaderBuilder lead="home" content={t("Authentication.title")} after="chat"/>}/>
                <Route path={RoutesName.Guide} element={<HeaderBuilder lead="home" content={t("Authentication.title")} after="chat"/>}/>

                <Route path={RoutesName.UploadImages} element={<HeaderBuilder lead="home" content={t("Authentication.title")} after="chat"/>}/>



                <Route path={RoutesName.Markets + ":symbol"} element={<HeaderBuilder lead="home" content={getCurrencyNameOrAlias(global?.currencies[symbol], language)} after="chat"/>}/>

                <Route path={RoutesName.HistoryRelative + "/*"} element={<HeaderBuilder lead="home" content={t("History.title")} after="chat"/>}/>
            </Routes>
        </div>
    );
};

export default Header;
