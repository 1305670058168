import {useQuery} from "@tanstack/react-query";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import useAuth from "../../Hooks/useAuth";

export const useGetKycStatus = (config = {}) => {
    const axiosPrivate =  useAxiosPrivate()
    const {auth} = useAuth();
    return useQuery(
        ['kyc-status', auth?.mobile],
        async () => {
            const {data} = await axiosPrivate.get(`/api/v1/kyc/status`)

            return data?.data;
        },
        config);
}