import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./assets/fontIcon/css/polban-icon.css";
import "./i18n/i18n";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AuthProvider} from "./context/AuthProvider";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import Main from "./main/Main";
import toast, {ToastBar, Toaster} from "react-hot-toast";
import i18n from "i18next";
import Icons from "./components/Icon/Icons";
import {GlobalProvider} from "./context/GlobalProvider";
import 'react-tooltip/dist/react-tooltip.css';
/*import * as Sentry from "@sentry/react";*/

/*Sentry.init({
    dsn: "https://10f44c2d8f26fe94f076449294b31fad@o4507774624530432.ingest.de.sentry.io/4507775990104144",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/app\.polban\.ir/,/^https:\/\/otc\.citex\.con/],
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});*/


const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));


/*const Toast = () => {
    console.log(t)
    return <Toaster position="top-right" toastOptions={
        {
            className: `${i18n.language !== "fa" ? "ltr" : "rtl"}`,
            style: {
                padding: "0.7vh 2vw 0.7vh 2vw",
                color: "white",
                lineHeight: "4vh",
                /!*fontSize: "0.8vw",*!/
                width: "100%",
                /!*borderRadius: "100px",*!/
                background: "var(--mainContent)",
                marginTop: "0.12vh"
            },
            success: {
                style: {
                    background: "var(--blackGreen)",
                    border: "2px solid var(--darkGreen)"
                },
            },
            error: {
                style: {
                    background: "var(--blackRed)",
                    border: "2px solid var(--darkRed)"
                },
            },
            custom: {
                style: {
                    background: "var(--Orange)",
                },
            },
        }} containerStyle={{}}/>
}*/

const Toast = () => {
    return <Toaster position="top-right" toastOptions={{
        className: `${i18n.language !== "fa" ? "ltr" : "rtl"}`,
        style: {
            color: "white",
            lineHeight: "4vh",
            width: "100%",
            background: "var(--mainContent)",
        },
        success: {
            duration: 8000,
            style: {
                background: "var(--blackGreen)",
                border: "2px solid var(--darkGreen)"
            },
        },
        error: {
            style: {
                background: "var(--blackRed)",
                border: "2px solid var(--darkRed)"
            },
        },
        custom: {
            style: {
                background: "var(--Orange)",
            },
        },
    }} containerStyle={{}}>
        {(t) => {
            return <ToastBar toast={t}>
                {({ icon, message }) => (
                    <>
                        {t.type !== 'loading' && (
                            <Icons
                                iconName="icon-cancel fs-05 flex "
                                iconClass={`toastIcon cursor-pointer mx-2`}
                                onClick={() => toast.dismiss(t.id)}
                            />
                        )}
                        {message}
                    </>
                )}
            </ToastBar>;
        }}
    </Toaster>;
}





root.render(
    <React.StrictMode>
        <GlobalProvider>
            <AuthProvider>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/*" element={<Main/>}/>
                        </Routes>
                    </BrowserRouter>
                    <Toast/>
                    <ReactQueryDevtools initialIsOpen={false}/>
                </QueryClientProvider>
            </AuthProvider>
        </GlobalProvider>
    </React.StrictMode>
);
