import React from 'react';
import classes from './WalletCard.module.css'
import {Trans, useTranslation} from "react-i18next";
import {BN, getCurrencyNameOrAlias, ratePrint} from "../../../../../../utils/utils";
import useGlobal from "../../../../../../Hooks/useGlobal";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";

const WalletCard = ({data, wallet}) => {


    const {t} = useTranslation();
    const navigate = useNavigate();

    const {global, setGlobal} = useGlobal();

    const direction = global?.direction
    const language = global?.language

    const isActive = data?.isActive

    const onclickHandler = () => {

      if (isActive) return navigate(RoutesName.Markets + data?.symbol);
      else return toast.error(
          <Trans
              i18nKey="inactiveMessage"
              values={{
                  currency: getCurrencyNameOrAlias(data, language),
              }}
          />
      );
    }


    const rateHandler = () => {

        if (!wallet?.equivalent) return <span>---</span>
        const newObject = [];

        Object.keys(wallet?.equivalent).forEach((key) => {
            newObject.push(
                <div className={`row`} onClick={(e)=> {
                    e.stopPropagation();
                    setGlobal({...global, hideUserBalance: !global.hideUserBalance})
                }} key={key}

                >
                    {direction === 'rtl' && <span className={`ml-1 text-gray fs-0-9`}>{key}</span>}
                    <span>{ global?.hideUserBalance ? "*****" : ((wallet?.equivalent[key] === "0") && (wallet?.balance !== 0)) ? "---" :

                        new BN(wallet?.balance)?.decimalPlaces(data?.precision)?.isZero() ? 0 :

                        new BN(wallet?.equivalent[key])?.decimalPlaces(global?.currencies[key]?.precision).toFormat() ?? 0 }


                    </span>
                    {direction === 'ltr' && <span className={`mr-1 text-gray fs-0-9`}>{key}</span>}
                </div>)
        });
        return newObject;
    }

    return (
        <div className={`width-90 py-2 row jc-between ai-center ${classes.container} ${!isActive && classes.disabled}`} onClick={()=>onclickHandler()}>
            {/* <div className={`${classes.circle} width-23 flex jc-start ai-center`}
                 style={{backgroundImage: `url("${data?.image_url}")`}}
            >

            </div>*/}

            <img src={data?.icon} alt="" className={`${classes.circle} ${classes.icon} width-23 flex jc-start ai-center ${!isActive && classes.iconDisabled}`} />

            <div className={`${classes.rectangle} width-76 row jc-between ai-center`}>
                <div className={`width-50 column jc-center ai-start`}>
                    <div className="row">
                      <span
                          className="ml-05 fs-02 font-weight-bold"
                          onClick={(e) => {
                              e.stopPropagation();
                              setGlobal({ ...global, hideUserBalance: !global.hideUserBalance });
                          }}
                      >
                        {global?.hideUserBalance
                            ? "*****"
                            : new BN(wallet?.balance)
                            ?.decimalPlaces(data?.precision)
                            .toFormat() ?? 0}
                      </span>
                    </div>

                    <span className={`row jc-center ai-center ${classes.name}`}>{ getCurrencyNameOrAlias(data, language)} <span className={`text-gray fs-0-9 ${classes.symbol}`}>({data?.symbol})</span></span>
                </div>

                <div className={`width-50 column jc-center ai-end`}>
                    {rateHandler()}
                    {/*<span className={` ${classes.rate} fs-01`}>{data?.rate?.rate?.toLocaleString()}</span>*/}
                    {/*<span className={` text-gray`}>{data?.rate?.rate ? t("currency."+ data?.rate?.destSymbol) : "- - -"}</span>*/}
                    {/*<span className={` text-gray `}>{}</span>*/}

                </div>
            </div>
        </div>
    );
};

export default WalletCard;


/*new BN(wallet?.balance).decimalPlaces(data?.precision).toFormat()*/
