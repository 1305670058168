import React, {useState} from 'react';
import classes from './Account.module.css';
import {Trans, useTranslation} from "react-i18next";
import Icons from "../../../../../../components/Icon/Icons";
import {ibanHandler} from "../../../../../../utils/iban";
import {images} from "../../../../../../assets/images/images";
import toast from "react-hot-toast";
import Icon from "../../../../../../components/Icon/Icons";
import Button from "../../../../../../components/Button/Button";
import Loading from "../../../../../../components/Loading/Loading";
import {createAccount, deleteAccount} from "../../../../../../api/auth";
import useAuth from "../../../../../../Hooks/useAuth";
import {useGetBankAccounts} from "../../../../../../query";

const Account = ({data}) => {

    const {t} = useTranslation();
    const {auth} = useAuth();

    const [loading, setLoading] = useState(false)

    const [isOpen, setIsOpen] = useState(false);

    const {refetch} = useGetBankAccounts()


    const statusHandler = (status) => {
        if (status === "Approved") return "text-green"
        if (status === "Rejected") return "text-red"
        return "text-color"
    }

    const copyToClipboard = (e, value) => {
        e.stopPropagation();
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success(t("copy"));
    }

    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('delete')
    }


    const deleteAccountHandler = () => {
        setLoading(true)
        deleteAccount(data?.id, auth?.token)
            .then(async (res) => {
                refetch()
                toast.success(t("LinkedBankAccounts.deleted"))
            }).catch(err => {

            toast.error(err?.response?.data?.message)

        }).finally(() => {

            setLoading(false)

        })

    }

    return (
        <div className={`${classes.container} width-100 rounded-8 column mb-2`}>
            <div className={`row jc-between ai-center `} onClick={()=>setIsOpen(prevState => !prevState)}>
                <div className={`${classes.logo} width-26`}
                     style={{backgroundImage: `url("${images[data?.bank]}")`}}
                />
                <div className={`column jc-start width-74`}>
                    <div className={`row jc-between`}>
                         <span className={`font-weight-bold`}>
                            <Trans
                                i18nKey="LinkedBankAccounts.bankName"
                                values={{
                                    name: t("IBAN."+ data?.bank),
                                }}
                            />
                        </span>

                        <span className={` fs-0-9 ${statusHandler(data?.status)}`}>
                            {t("LinkedBankAccounts."+ data?.status)}
                        </span>
                    </div>

                    <div className={`row jc-between ai-center `}>
                        {
                            data?.card !== null && <span className={`direction-ltr unicode-bidi-embed`}>{data?.card?.match(new RegExp('.{1,4}', 'g')).join(" - ")}</span>
                        }
                        {
                            data?.iban !== null && <span className={`direction-ltr`}>{data?.iban}</span>
                        }
                        <Icon
                            iconName={`icon-copy fs-02 flex cursor-pointer`}
                            iconClass={``}
                            onClick={(e) => copyToClipboard(e, data?.card !== null ?  data?.card : data?.iban)}

                        />
                    </div>

                  {/*  <Icons
                        iconName={`${isOpen ? 'icon-up-open' : 'icon-down-open'} flex fs-04`}
                        iconClass={`cursor-pointer`}
                    />*/}
                </div>
            </div>
            {isOpen && <div className={`column jc-center ai-center mt-2`}>
                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                    buttonTitle={buttonTitleHandler()}
                    disabled={loading}
                    onClick={()=>deleteAccountHandler()}
                />
            </div>}
        </div>
    );
};

export default Account;
