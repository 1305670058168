import React from 'react';
import classes from './Rules.module.css';
import ScrollBar from "../../../../components/ScrollBar";
import {useTranslation} from "react-i18next";
import {useGetRules} from "../../../../query/hooks/useGetRules";
import parse from 'html-react-parser';

const Rules = () => {

    const {t} = useTranslation();
    const {data, isLoading, error} = useGetRules();

    const content = () => {
        if (isLoading) return <>
            <div className={`${classes.loading} width-85 my-1`}/>
            <div className={`${classes.loading} width-85 my-1`}/>
            <div className={`${classes.loading} width-85 my-1`}/>
            <div className={`${classes.loading} width-85 my-1`}/>
        </>
        if (error) return <>
            <span className={`my-10`}>{t("error")}</span>
        </>
        if (data?.length <= 0 || data === undefined || data === null) {
            return <span className={`my-10`}>{t("noData")}</span>
        }
        return <>
            {parse(data)}
        </>
    }

    return (
        <ScrollBar>
            <div className={`${classes.container} width-86 column jc-start ai-center py-3 px-5 m-auto`}>
                {content()}
            </div>
        </ScrollBar>
    );
};

export default Rules;
