import React from 'react';
import classes from './Terminals.module.css'
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import Gateways from "../Gateways/Gateways";
import {useGetGateways, useGetTerminals} from "../../../../../../query";
import TerminalsCard from "../TerminalsCard/TerminalsCard";

const Terminals = () => {

    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();
    const gateway = global?.selected_deposit_network

    const method = gateway?.transferMethod?.toLowerCase()


    const {data, isLoading, error} = useGetTerminals(gateway?.gatewayUuid)

    const content = () => {

        if (isLoading) {
            return <span className={`my-10`}>{t("loading")}</span>
        }
        if (error) {
            return <span className={`my-10`}>{t("error")}</span>
        }
        if (data?.length <= 0) {
            return <span className={`my-10`}>{t("noData")}</span>
        }

        return data?.filter((f) =>(f?.active)).map((terminal) => <TerminalsCard terminal={terminal}/>)


    }

    return (
        content()
    );
};

export default Terminals;
