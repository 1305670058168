import React, {useEffect, useRef} from 'react';
import classes from './Messages.module.css';
import {useTranslation} from "react-i18next";
import {useGetMessages} from "../../../../../../query";
import Message from "../Message/Message";
import ScrollBar from "../../../../../../components/ScrollBar";
import {useLocation} from "react-router-dom";
import {images} from "../../../../../../assets/images/images";
import Button from "../../../../../../components/Button/Button";
import moment from "moment-jalaali";
import {calendarsMap} from "../../../../../../utils/calendarsMap";
import useGlobal from "../../../../../../Hooks/useGlobal";
import DateDisplay from "../../../../../../components/DateDisplay/DateDisplay";


const Messages = () => {

    const {t} = useTranslation();
    const location = useLocation();

    const { global } = useGlobal();

    const {data, isLoading, error, refetch} = useGetMessages()

    const scrollRef = useRef(null);

    useEffect(() => {
        scrollRef.current?.scrollIntoView(/*{behavior: 'smooth'}*/);
    }, [data, location]);

    const groupMessagesByDate = (messages) => {
        return messages.reduce((acc, message) => {
            const date = moment(message.created_at).format('YYYY-MM-DD');
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(message);
            return acc;
        }, {});
    };

    const content = () => {
        if (isLoading) {
            return <div className={`width-100 column jc-center ai-center height-100`}>

                <img src={images.inbox} className={`${classes.image} mb-1`} alt=""/>

                <span className={`mt-1`}>{t("loading")}</span>
            </div>
        }
        if (error) {
            return <div className={`width-100 column jc-center ai-center height-100`}>

                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} cursor-pointer rounded-100-p mb-1 px-6`}
                    buttonTitle={t('retry')}
                    onClick={()=>refetch()}
                />
                <span className={`mt-1`}>{t("error")}</span>
            </div>
        }
        if (data?.length <= 0) {
            return <div className={`width-100 column jc-center ai-center height-100`}>
                <img src={images.file} className={`${classes.image} mb-1`} alt=""/>

                <span className={`mt-1`}>{t("noMessages")}</span>
            </div>
        }

        const groupedMessages = groupMessagesByDate(data);
        const dates = Object.keys(groupedMessages).sort((a, b) => moment(a).unix() - moment(b).unix());

        return <div className={`width-100 column jc-end min-height-100`}>
            <div className={`width-100 text-center flex jc-center ai-center my-2`}>
                <span className={` width-100 py-1`}>{t("Chat.hint")}</span>
            </div>
            {dates.map((date) => (
                <div key={date}>
                    <DateDisplay
                        date={date}
                        calendarType={calendarsMap[global.calendar]}
                        localeType={calendarsMap[global.datePickerLocale]}
                        classes={classes}
                    />
                    {groupedMessages[date].map((message) => (
                        <Message data={message} key={message.id} />
                    ))}
                </div>
            ))}
            <span ref={scrollRef} />
        </div>
    }

    return (
        <div className={`${classes.container} width-100 height-100 mb-1`}>
            <ScrollBar>
                {content()}
            </ScrollBar>
        </div>
    );
};

export default Messages;