import React, {useEffect} from 'react';
import classes from './MainMenu.module.css'
import Icons from "../../../../../../components/Icon/Icons";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";
import {useGetKycStatus} from "../../../../../../query";
import toast from "react-hot-toast";

const MainMenu = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {data} = useGetKycStatus()


    useEffect(() => {
        return () => {

            /*toast.remove();*/
        };
    }, []);


    const disabled = () =>{
        if (data?.kyc_status === "Approved") return false
        else return true
    }

    const navigateHandler = (route) => {
        if (data?.kyc_status === "Approved") return navigate(route)
        else return toast.error(t("doKYC"))
    }


    return (
        <div className={`${classes.container} rounded-8 position-absolute width-86`}>

            <div className={`row jc-between ai-center`}>

                <Link to={RoutesName.Deposit} className={`column jc-center ai-center cursor-pointer`}>
                    <div className={`${classes.box} flex jc-center ai-center rounded-8 mb-1`}>
                        <Icons
                            iconName={`icon-deposit fs-25 text-blue flex jc-center ai-center ${classes.first}`}
                            iconClass={`cursor-pointer`}
                        />
                    </div>
                    <span className={`mt-05 fs-01`}>{t('Deposit.title')}</span>
                </Link>
                <div onClick={()=>navigateHandler(RoutesName.Exchange)} className={`column jc-center ai-center cursor-pointer`}>
                    <div className={`${classes.box} ${disabled() && classes.disabled} flex jc-center ai-center rounded-8 mb-1`}>
                        <Icons
                            iconName=" icon-exchange fs-25 text-blue flex jc-center ai-center"
                            iconClass={`cursor-pointer`}
                        />
                    </div>
                    <span className={`mt-05 fs-01 ${disabled() && 'text-gray'}`}>{t('exchange')}</span>
                </div>
                <div onClick={()=>navigateHandler(RoutesName.Withdrawal)} className={`column jc-center ai-center cursor-pointer`}>
                    <div className={`${classes.box} ${disabled() && classes.disabled} flex jc-center ai-center rounded-8 mb-1`}>
                        <Icons
                            iconName=" icon-withdrawal fs-25 text-blue flex jc-center ai-center"
                            iconClass={`cursor-pointer`}
                        />
                    </div>
                    <span className={`mt-05 fs-01 ${disabled() && 'text-gray'}`}>{t('Withdrawal.title')}</span>
                </div>

            </div>

        </div>


        /*

        <div className={`${classes.container} rounded-8 px-5 py-3 position-absolute width-86`}>

            <div className={`row jc-between ai-center`}>

                <div onClick={()=>navigateHandler(RoutesName.Deposit)} className={`column jc-center ai-center `}>
                    <div className={`${classes.box} ${disabled() && classes.disabled} flex jc-center ai-center rounded-8 mb-1`}>
                        <Icons
                            iconName={`icon-deposit fs-25 text-blue flex jc-center ai-center ${classes.first}`}
                            iconClass={`cursor-pointer`}
                        />
                    </div>
                    <span className={`mt-05 fs-01 ${disabled() && 'text-gray'}`}>{t('Deposit.title')}</span>
                </div>
                <div onClick={()=>navigateHandler(RoutesName.Exchange)} className={`column jc-center ai-center`}>
                    <div className={`${classes.box} ${disabled() && classes.disabled} flex jc-center ai-center rounded-8 mb-1`}>
                        <Icons
                            iconName=" icon-exchange fs-25 text-blue flex jc-center ai-center"
                            iconClass={`cursor-pointer`}
                        />
                    </div>
                    <span className={`mt-05 fs-01 ${disabled() && 'text-gray'}`}>{t('exchange')}</span>
                </div>
                <Link to={RoutesName.Withdrawal} className={`column jc-center ai-center`}>
                    <div className={`${classes.box} flex jc-center ai-center rounded-8 mb-1`}>
                        <Icons
                            iconName=" icon-withdrawal fs-25 text-blue flex jc-center ai-center"
                            iconClass={`cursor-pointer`}
                        />
                    </div>
                    <span className={`mt-05 fs-01`}>{t('Withdrawal.title')}</span>
                </Link>

            </div>

        </div>

        */
    );
};

export default MainMenu;
