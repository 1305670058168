import {useQuery} from "@tanstack/react-query";
import useAuth from "../../Hooks/useAuth";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";

export const useGetUserInfo = () => {

    const axiosPrivate =  useAxiosPrivate()
    const {auth} = useAuth();

    return useQuery(
        ['user-info', auth?.mobile],
        async () => {
            const {data} = await axiosPrivate.get(`/api/v1/me`)
            return data?.data;
        });
}