import React, {useEffect, useState} from 'react';
import classes from './NavBar.module.css'

import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";

const NavBar = ({title, navClass, tab, activeTab, setActiveTab ,...props}) => {


    const {t} = useTranslation();

    const [active, setActive] = useState(0);

    const num = tab?.length


    const itemsClickHandler = (index, action, disabled) => {

        if (disabled) {
            toast.error(t("doKYC"))
            return false;
        }

        setActive(index);
        action()
    };

    useEffect(() => {
        if (activeTab !== undefined) setActive(activeTab)
    }, [activeTab]);

    useEffect(() => {
        if (typeof setActiveTab === "function") setActiveTab(active)
    }, [active,setActiveTab]);

    return (
        <div className={`row ${classes.container} ${navClass}  ${num === 2 && classes.two}`}>

            {tab.map((item, index) => {
                return (
                    <div
                        className={` ${active === index ? classes.active : ""} width-50 py-1 text-center ${classes.item} ${item?.disabled && classes.disabled} cursor-pointer`}
                        onClick={() => itemsClickHandler(index, item?.action,item?.disabled )}
                        key={index}>
                        {item.title}
                    </div>
                );
            })}
            <div> </div>


        </div>
    );
};

export default NavBar;
